<template>
  <div>
    <div class="ls_flexalign_center" style="margin-bottom: 15px">
      <el-input
        v-model="params.name"
        placeholder="搜索名称"
        style="width: 150px"
      />
      <el-cascader
        v-model="params.classifyId2"
        :options="labelList"
        :show-all-levels="false"
        :props="{
          children: 'child',
          value: 'id',
          label: 'name',
          checkStrictly: true,
          emitPath: false,
        }"
      ></el-cascader>
      <el-select v-model="params.status" placeholder="请选择">
        <el-option value="" label="全部状态" />
        <el-option :value="1" label="正常" />
        <el-option :value="2" label="下架" />
      </el-select>
      <el-select v-model="params.publicType" placeholder="请选择">
        <el-option :value="1" label="公共素材" />
        <el-option :value="2" label="私有素材" />
      </el-select>
      <el-button
        type="primary"
        @click="
          params.pageIndex = 1;
          getList();
        "
        >搜索</el-button
      >
      <div class="ls_flex_1"></div>
      <el-button
        type="success"
        @click="$router.push('/materials/container/detail')"
        >新增素材</el-button
      >
    </div>

    <el-table
      :data="list"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="标题">
        <template slot-scope="scope">
          <div class="ls_flexalign_center">
            <img :src="scope.row.coverImage | imgFormat" style="width:40px;height:40px;margin-right:15px"/>
            <div>{{ scope.row.name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="classifyName2" label="标签"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.status === 1 ? "使用中" : "已下架" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="
              $router.push(
                '/materials/container/detail?id=' +
                  scope.row.id +
                  '&isPublic=true'
              )
            "
            v-if="publicType === 1"
            >查看详情</el-button
          >
          <template v-else>
            <el-button
              type="text"
              @click="$router.push('/container/detail?id=' + scope.row.id)"
              >编辑</el-button
            >
            <el-popconfirm title="确定要删除吗？" @confirm="del(scope.row.id)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
            <el-button type="text" @click="changeStatus(scope.row)">{{
              scope.row.status === 1 ? "下架" : "上架"
            }}</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="批量标签、命名" :visible.sync="mainShow">
      <el-form label-width="110px">
        <el-form-item label="分类">
          <el-select v-model="mainParams.classifyId2" placeholder="请选择">
            <el-option
              :label="item.name"
              :value="item.id"
              :key="item.id"
              v-for="item in labelList"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="命名">
          <el-input v-model="mainParams.name"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="handleBatch(mainParams)"
            >确认</el-button
          >
          <el-button @click="reset('main')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="批量详细" :visible.sync="detailShow">
      <el-form label-width="110px">
        <el-form-item label="基础信息">
          <el-input v-model="detailParams.basicInfo" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="文化内涵">
          <el-input
            v-model="detailParams.connotation"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="日常使用搭配">
          <el-input
            v-model="detailParams.collocation"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="色彩搭配建议">
          <el-input
            v-model="detailParams.colorSuggestion"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="技术使用建议">
          <el-input
            v-model="detailParams.technicalSuggestion"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="寓意">
          <el-input v-model="detailParams.allegory" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="构图建议">
          <el-input
            v-model="detailParams.compositionSuggestion"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-button type="primary" @click="handleBatch(detailParams)"
            >确认</el-button
          >
          <el-button @click="reset('detail')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <div style="margin-top: 15px">
      <template v-if="publicType === 2">
        <el-button @click="mainShow = true">批量分类、命名</el-button>
        <el-button @click="handleBatch({ status: 1 })">上架</el-button>
        <el-button @click="handleBatch({ status: 2 })">下架</el-button>
        <el-button @click="detailShow = true">批量详细</el-button>
      </template>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page.sync="params.pageIndex"
        @current-change="getList"
        :page-size.sync="params.pageSize"
        style="float: right"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicType: 2,
      mainShow: false,
      mainParams: {
        classifyId2: "",
        name: "",
      },
      detailShow: false,
      detailParams: {
        basicInfo: "",
        connotation: "",
        collocation: "",
        colorSuggestion: "",
        technicalSuggestion: "",
        compositionSuggestion: "",
        allegory: "",
      },
      params: {
        pageIndex: 1,
        pageSize: 8,
        name: "",
        classifyId1: 2,
        classifyId2: "",
        status: "",
        publicType: 2,
      },
      labelList: [],
      total: 0,
      list: [],
      multipleSelection: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    reset(type) {
      if (type === "detail") {
        this.detailParams = {
          basicInfo: "",
          connotation: "",
          collocation: "",
          colorSuggestion: "",
          technicalSuggestion: "",
          compositionSuggestion: "",
          allegory: "",
        };
        this.detailShow = false;
      } else {
        this.mainParams = {
          classifyId2: "",
          name: "",
        };
        this.mainShow = false;
      }
    },
    handleBatch(params) {
      this.$confirm("确定要批量修改吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$ajax
            .post("/material/batchUpdate", {
              ids: this.multipleSelection.map((item) => {
                return item.id;
              }),
              ...params,
            })
            .then((res) => {
              this.$message.success("修改成功");
              this.reset("detail");
              this.reset("main");
              this.getList();
            });
        })
        .catch(() => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    init() {
      this.params.pageIndex = 1;
      this.params.classifyId1 = this.$route.query.type || 2;
      this.getList();
      this.getLabelList();
    },
    changeStatus(item) {
      this.$ajax
        .post("/material/batchUpdate", {
          ids: [item.id],
          status: item.status === 1 ? 2 : 1,
        })
        .then((res) => {
          this.$message.success("修改成功");
          this.getList();
        });
    },
    del(id) {
      this.$ajax.get("/material/delete?id=" + id).then((res) => {
        this.$message.success("删除成功");
        this.getList();
      });
    },
    getList() {
      this.publicType = this.params.publicType;
      this.$ajax.post("/material/queryList", this.params).then((res) => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    getLabelList() {
      this.$ajax.post("/admin/classifyManage/queryAll").then((res) => {
        this.labelList = res.data.find(
          (item) => item.id == this.params.classifyId1
        ).child;
      });
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.ls_flexalign_center {
  > div {
    margin-right: 15px;
  }
}
</style>